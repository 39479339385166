<template>
    <div class="container-fluid">
        <div class="row p-4">
            <h3 class="text-center w-100"><strong>{{advertise}}</strong></h3>
            <i class="text-center w-100 far fa-dizzy fa-10x"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmptyContent",
    props:{
        advertise: {
            type: String,
            required: true
        }
    }
}
</script>