<template>
    <div class="container-fluid p-2">
        <div class="row p-0 m-0">
            <button class="btn btn-info" @click="form_show = !form_show">
                <div v-if="!form_show" >
                    <i class="fas fa-chevron-down"></i><span class="ml-2">Aggiungi Nuovo</span>
                </div>
                <div v-if="form_show">
                    <i v-if="form_show" class="fas fa-chevron-up"></i><span class="ml-2">Nascondi Nuovo</span>
                </div>
            </button>
        </div>
        <div v-if="form_show" class="mt-2 row m-0 p-0 rounded bg-white border">
            <div class="col-12 m-0 p-0">
                <div class="bg-dark m-0 p-2 col-12">
                    <strong class="text-white">NUOVA GUIDA</strong>
                </div>
                <div class="col-12 p-2 m-0">
                    <div class="col-12 p-0 d-flex justify-content-end" v-if="id != -1 ? true : false">
                        <button class="btn btn-danger" @click.prevent="editSelectedTutorial(-1)"><i class="fa fa-times"></i></button>
                    </div>
                    <form class="container-fluid p-0 m-0" id="tutorial_edit" @submit.prevent="tutorialsEdit('edit')">
                        <div class="container-fluid">
                            <input hidden v-model="id" type="text">
                            <div class="row mt-2">
                                <p class="col-2 m-0 mt-2 text-center"><b>Titolo:</b></p>
                                <input class="col-10" v-model="title" placeholder="Titolo" type="text">
                            </div>
                            <div class="row mt-2">
                                <p class="col-2 m-0 mt-2 text-center"><b>Slug:</b></p>
                                <input class="col-10" v-model="slug" placeholder="Slug" type="text">
                            </div>
                            <div class="row mt-2">
                                <select class="col-3 text-center" v-model="active">
                                    <option value="1">attiva</option>
                                    <option value="0">disattiva</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2 w-100 p-0 m-0">
                            <input class="col-12 m-0 p-0 btn btn-success p-2" type="submit" value="Crea">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row p-0 m-0 mt-2">
            <button class="btn btn-info m-0" @click="list_show = !list_show">
                <div v-if="!list_show" >
                    <i class="fas fa-chevron-down"></i><span class="ml-2">Mostra Lista</span>
                </div>
                <div v-if="list_show">
                    <i v-if="list_show" class="fas fa-chevron-up"></i><span class="ml-2">Nascondi Lista</span>
                </div>
            </button>
        </div>
        <div v-if="list_show" class="container-fluid mt-2 bg-white border rounded">
            <div v-for="(tutorial,index) in tutorialsList" v-bind:key="tutorial.id">
                <div class="row rounded">
                    <div class="col-12">
                        <span class="w-100"><b>n. {{Number(index) + 1}}</b></span>
                    </div>
                    <div class="col-12">
                        <span>{{tutorial.title}}</span>
                    </div>
                    <div class="col-12">
                        <router-link :to="`/guide/${tutorial.slug}`" target="_blank">{{tutorial.slug}}</router-link>
                    </div>
                    <div class="col-12 p-0 d-flex justify-content-between">
                        <div class="w-100 p-2">
                            <button v-if="tutorial.active == 1" class="w-100 btn btn-danger" @click.prevent="tutorialsEdit('toggle',tutorial.id)">Disattiva</button>
                            <button v-if="tutorial.active == 0" class="w-100 btn btn-success" @click.prevent="tutorialsEdit('toggle',tutorial.id)">Attiva</button>
                        </div>
                        <div class="w-100 p-2">
                            <button class="btn btn-primary w-100" @click.prevent="editSelectedTutorial(tutorial.id)">
                                <i class="fa fa-edit"></i>
                            </button>
                        </div>
                        <div class="w-100 p-2">
                            <button class="btn btn-info w-100" @click.prevent="active_content == tutorial.id ? active_content = -1 : active_content = tutorial.id">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                        <div class="w-100 p-2">
                            <button class="btn btn-danger w-100" @click.prevent="tutorialsEdit('delete',tutorial.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 p-0" v-if="active_content == tutorial.id">
                        <TutorialEditor v-bind:father_id="tutorial.id" />
                    </div>
                </div>
            </div>
            <div v-if="Object.keys(tutorialsList).length == 0">
                <EmptyContent :advertise="'Sembra che non ci siano guide!'"/>
            </div>
        </div>
    </div>
</template>

<script>
import TutorialEditor from './editors/TutorialEditor';
import EmptyContent from './advertising/EmptyContent';

export default {
    name:'TutorialsForm',
    data: function(){
        return {
            /*UI*/
            form_show: true,
            list_show: false,
            /*Form*/
            csrf_token: '',
            id: -1,
            slug: '',
            title: '',
            active: 1,
            /*List*/
            tutorialsList: {},
            /*Content*/
            active_content: -1
        }
    },
    beforeMount(){
        this.tutorialsEdit('list');
    },
    methods: {
        /*CRUD*/
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        tutorialsEdit: async function(value, id = -1){
            await this.getToken();
            var values = '';
            switch (value){
                case 'edit':
                    values = JSON.stringify({
                        csrf_token: this.csrf_token,
                        action: value,
                        id: this.id,
                        slug: this.slug,
                        title: this.title,
                        active: this.active
                    })
                break;
                case 'delete':
                    values = JSON.stringify({
                        csrf_token: this.csrf_token,
                        action: value,
                        id: id
                    })
                break;
                case 'list':
                    values = JSON.stringify({
                        csrf_token: this.csrf_token,
                        action: value,
                        id: id
                    })
                break;
                case 'toggle':
                    values = JSON.stringify({
                        csrf_token: this.csrf_token,
                        action: value,
                        id: id,
                    })
                break;
            }

            const res = await fetch(`${this.$api_url}/backend/tutorial/crud.php`,{
                method: "POST",
                body: values,
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                json.updated != undefined ? this.tutorialsEdit('list',json.updated) && this.$parent.setDialog('infos',['tutorial_success']) : 0 ;
                json.removed != undefined ? this.removeSelectedTutorial(json.removed) : 0;
                json.update != undefined ? this.updateSelectedTutorial(json[0].id,json[0]) : 0;
                json.errors != undefined ? this.$parent.setDialog('errors',json[0]) : 0;
                if(json.list != undefined){
                    delete json['list'];
                    this.tutorialsList = json;
                }
            }
        },
        editSelectedTutorial: function (_id){
            if(_id != -1){
                var cur_index = this.getIndexFromId(this.tutorialsList,_id);
                this.form_show = true;
                this.id = this.tutorialsList[cur_index].id;
                this.slug = this.tutorialsList[cur_index].slug; 
                this.title = this.tutorialsList[cur_index].title;
                this.active = this.tutorialsList[cur_index].active; 
            }else{
                this.id = -1;
                this.slug = '';
                this.title = '';
                this.active = 1;
            }
        },
        updateSelectedTutorial: function(_id,_data){
            delete _data['update'];
            var cur_index = this.getIndexFromId(this.tutorialsList,_id);
            if (cur_index != -1){
                this.$set(this.tutorialsList,[cur_index],_data)
            }else{
                this.id = -1
                this.slug = ''
                this.title = ''
                this.active = 1
                var index = Object.keys(this.tutorialsList).length;
                this.$set(this.tutorialsList,index,_data);
            }
        },
        removeSelectedTutorial: function(_id){
            var cur_index = this.getIndexFromId(this.tutorialsList,_id);
            this.$delete(this.tutorialsList,[cur_index]);
        },
        editSelectedContent: function (_id){
            this.active_content = _id
        },
        getIndexFromId: function (_obj,_id){
            var _index = -1
            for (var value in _obj){
                if(_obj[value].id == _id){
                    _index = value
                }
            }
            return _index;
        }
    },
    components: {
        TutorialEditor,
        EmptyContent
    }
}
</script>