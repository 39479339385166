<template>
    <!--TODO: aggiungere ordine-->
    <div class="container-fluid p-2">
        <button v-if="!new_editing" :disabled="current_active != -1" style="width:32px; height:32px;" class="p-0 btn btn-success" @click.prevent="new_editing=true">
            <i class="fa fa-edit"></i>
        </button>
        <button v-if="new_editing" style="width:32px; height:32px;" class="p-0 btn btn-danger" @click.prevent="new_editing=false">
            <i class="fa fa-times"></i>
        </button>
        <div class="mt-2" v-if="new_editing">
            <quillEditor 
                :options = quillOptions
                @change="onEditorChange($event),current_active = -1" 
            />
            <button class="btn btn-success mt-2" @click.prevent="contentsEdit('edit'),new_editing=false" :disabled="content == '' || current_active != -1">Save</button>
        </div>
        <hr>
        <div v-for="(container,index) in contentsList" :key="container.id">
            <div class="container-fluid border bg-white rounded m-0 p-2">
                <div class="row m-0">
                    <b class="col-2 p-1">n.{{index}}</b>
                    <div class="col-10 d-flex justify-content-end p-0">
                        <button class="btn btn-sm btn-info" @click.prevent="current_active == container.id ? editCurrentTextBlock(-1) : editCurrentTextBlock(container.id)">
                            <i v-if="current_active != container.id" class="p-0 fas fa-edit" style="width:16px; height:16px;"></i>
                            <i v-if="current_active == container.id" class="p-0 fas fa-times" style="width:16px; height:16px;"></i>
                        </button>
                        <button class="btn btn-sm btn-info ml-2" :disabled="content == '' || current_active != container.id" @click.prevent="contentsEdit('edit',container.id)">
                            <i class="fas fa-save" style="width:16px; height:16px;"></i>
                        </button>
                        <button class="btn btn-sm btn-info ml-2" @click.prevent="contentsEdit('delete',container.id)">
                            <i class="fa fa-trash" style="width:16px; height:16px;"></i>
                        </button>
                    </div>
                </div>
                <div v-if="current_active != container.id" style="word-wrap: break-word;" class="bg-white m-0 mt-2 p-1 border" v-html="container.content"></div>
                <div v-if="current_active == container.id" style="word-wrap: break-word;" class="m-0 mt-2 p-0">
                    <quillEditor :content=container.content @change="onEditorChange($event)" />
                </div>
                <AttachmentsEditor v-bind:father_id="container.id"/>
            </div>
        </div>
    </div>
</template>

<script>
/*QUILL EDITOR*/
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
/*FORM ATTACHMENT*/
import AttachmentsEditor from './AttachmentsEditor'

export default {
    name: 'TutorialEditor',
    data: function(){
        return {
            /*UI*/
            show_selection: false,
            new_editing: false,
            quillOptions: {
                    placeholder: "Pronto a scrivere una nuova guida?",
                },
            /*Form*/
            content:'',
            current_active: -1,
            csrf_token: '',
            /*List*/
            contentsList: {}
        }
    },
    props: {
        father_id: {
            type: Number,
            required: true
        }
    },
    mounted(){
        this.contentsEdit('list');
    },
    methods: {
        /*CRUD*/
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        contentsEdit: async function(value, id = -1){
            await this.getToken();
            var values = {};
            switch(value){
                case 'edit':
                    values = JSON.stringify({
                        action: value,
                        id: id,
                        content: this.content,
                        father_id: this.father_id,
                        csrf_token: this.csrf_token
                    })
                break;
                case 'delete':
                    values = JSON.stringify({
                        action: value,
                        id: id,
                        father_id: this.father_id,
                        csrf_token: this.csrf_token
                    })
                break;
                case 'list':
                    values = JSON.stringify({
                        action: value,
                        id: id,
                        father_id: this.father_id,
                        csrf_token: this.csrf_token
                    })
                break;
            }

            const res = await fetch(`${this.$api_url}/backend/tutorial/contents/crud.php`,{
                method: "POST",
                body: values,
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                json.updated != undefined ? this.contentsEdit('list',json.updated) && this.$parent.$parent.setDialog('infos',['content_success']) : 0;
                json.removed != undefined ? this.removeSelectedContent(json.removed) : 0;
                json.update != undefined ? this.updateSelectedContent(json[0].id,json[0]) : 0;
                json.errors != undefined ? this.$parent.$parent.setDialog('errors',json[0]) : 0;
                if(json.list != undefined){
                    delete json['list'];
                    this.contentsList = json
                }
            }
        },
        updateSelectedContent: function (_id,_data){
            delete _data['update'];
            var cur_index = this.getIndexFromId(this.contentsList,_id);
            if (cur_index != -1){
                this.$set(this.contentsList,[cur_index],_data)
            }else{
                var index = Object.keys(this.contentsList).length +1;
                this.$set(this.contentsList,index,_data);
            }
        },
        removeSelectedContent: function (_id){
            var cur_index = this.getIndexFromId(this.contentsList,_id);
            this.$delete(this.contentsList,[cur_index])
        },
        getIndexFromId: function (_obj,_id){
            var _index = -1
            for (var value in _obj){
                if(_obj[value].id == _id){
                    _index = value
                }
            }
            return _index;
        },
        /*TEXT EDITOR*/
        onEditorChange({html}){
            this.content = html
        },
        editCurrentTextBlock(_id){
            this.current_active = _id;
            this.content = '';
        }
    },
    components:{
        quillEditor,
        AttachmentsEditor
    }
}
</script>

<style>
    .quill-editor .ql-font{
        display: none;
    }
</style>