<template>
    <div class="container-fluid p-2">
        <div class="col-12 m-0 border bg-white rounded">
            <form class="p-2" id="profile_edit" @submit.prevent="profileEdit('edit')">
                <div class="row">
                    <div class="col-4 p-1">
                        <button class="w-100 btn btn-info" @click.prevent="showCurrent('change_email')">Cambia Email</button>
                    </div>
                    <div class="col-4 p-1">
                        <button class="w-100 btn btn-info" @click.prevent="showCurrent('change_username')">Cambia Username</button>
                    </div>
                    <div class="col-4 p-1">
                        <button class="w-100 btn btn-info" @click.prevent="showCurrent('change_password')">Cambia Password</button>
                    </div>
                </div>
                <div v-if="change_email" class="row">
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>Username:</b></span>
                        <input class="col-8" v-model="username" type="text">
                    </div>
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>New E-mail:</b></span>
                        <input class="col-8" v-model="e_mail" type="text">
                    </div>
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>Password:</b></span>
                        <input class="col-8" v-model="password" type="password">
                    </div>
                </div>
                <div v-if="change_username" class="row">
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>E-mail:</b></span>
                        <input class="col-8" v-model="e_mail" type="text">
                    </div>
                        <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>New Username:</b></span>
                    <input class="col-8" v-model="username" type="text">
                    </div>
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>Password:</b></span>
                        <input class="col-8" v-model="password" type="password">
                    </div>
                </div>
                <div v-if="change_password" class="row">
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>E-mail:</b></span>
                        <input class="col-8" v-model="e_mail" type="text">
                    </div>
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>New Username:</b></span>
                        <input class="col-8" v-model="username" type="text">
                    </div>
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>Nuova password:</b></span>
                        <input class="col-8" v-model="new_password" type="password">
                    </div>
                    <div class="p-1 col-12 row m-0">
                        <span class="col-4 mt-1 p-0"><b>Conferma password:</b></span>
                        <input class="col-8" v-model="confirm_new_password" type="password">
                    </div>
                </div>
                <div class="row mt-2">
                    <input class="w-100 btn btn-success ml-1 mr-1" type="submit" value="Modifica">
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name:'ProfileForm',
    data: function (){
        return {
            /*UI*/
            change_password: false,
            change_username: false,
            change_email: false,
            /*CRUD*/
            csrf_token: '',
            username: '',
            password: '',
            e_mail: '',
            new_password: '',
            confirm_new_password: '',
        }
    },
    mounted(){
        this.getToken();
    },
    methods: {
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        /*UI*/
        showCurrent(selected){
            this.change_password = false;
            this.change_username = false;
            this.change_email = false;

            this.username = '';
            this.e_mail = '';
            this.new_password = '';
            this.confirm_new_password = '';

            this[selected] = true;
        },
        /*CRUD*/
        profileEdit: async function(value){
            var formData = new FormData();
            formData.append('action',value);
            formData.append('csrf_token',this.csrf_token);
            if(this.change_password != false){
                formData.append('modify','password');
                formData.append('username',this.username);
                formData.append('e_mail',this.e_mail);
                formData.append('new_password',this.new_password);
                formData.append('confirm_new_password',this.confirm_new_password);
            }else if(this.change_username != false){
                formData.append('modify','username');
                formData.append('e_mail',this.e_mail);
                formData.append('username',this.username);
                formData.append('password',this.password);
            }else if(this.change_email){
                formData.append('modify','email');
                formData.append('e_mail',this.e_mail);
                formData.append('username',this.username);
                formData.append('password',this.password);
            }
            if(this.change_password != false || this.change_username != false || this.change_email != false){
                const res = await fetch(`${this.$api_url}/backend/profile/crud.php`, {
                    method: "POST",
                    body: formData,
                    credentials: 'include'
                });
                if (res.status != 200){
                    this.$parent.setDialog('errors',['unexpected_error']);
                }else{
                    const json = await res.json();
                    json.errors != undefined ? this.$parent.setDialog('errors',json[0]) : 0;
                    json.updated != undefined ? this.$parent.setDialog('infos',json[0]) : 0;
                }
            }
        }
    }
}
</script>