<template>
    <div class="container-fluid p-0 border mt-2">
        <div class="row m-0 d-flex align-items-center">
            <span class="col-3 p-1"><i class="ml-1 fas fa-paperclip"></i><b> Allegati</b></span>
            <div class="col-9 p-0 d-flex justify-content-end" >
                <input type="file" id="file" ref="file" @change="fileUpload()">
            </div>
        </div>
        <div class="row m-0 d-flex border-top bg-light">
            <div class="w-100 p-0" v-for="(attachment) in attachmentList" :key="attachment.id">
                <div class=" m-2 p-2">
                    <div class="row w-100 p-0 m-0">
                        <a class="flex-grow-1" :href="attachment.location">{{attachment.location}}</a>
                        <button class="flex-grow-0 btn btn-sm d-flex justify-content-end" @click.prevent="attachmentsEdit('delete',attachment.id)">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="w-100 d-flex justify-content-center">
                        <img class="mt-1" style="width:auto; max-width:120px; max-height:120px;"  :src="attachment.location">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'AttachmentsEditor',
    data: function(){
        return {
            /*Form*/
            id: -1,
            slug: '',
            file: '',
            csrf_token: '',
            attachmentList:{}
        }
    },
    props: {
        father_id:{
            type: Number,
            required: true
        }
    },
    mounted(){
        this.attachmentsEdit('list');
    },
    methods:{
        fileUpload: function (){
            this.file = this.$refs.file.files[0];
            this.attachmentsEdit('edit')
        },
        /*CRUD*/
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        attachmentsEdit: async function (value, id = -1){
            await this.getToken();
            var formData = new FormData();
            formData.append('csrf_token',this.csrf_token)
            formData.append('action',value);
            formData.append('father_id',this.father_id)
            formData.append('id',id);
            switch(value){
                case 'edit':
                    formData.append('file',this.file);
                break;
            }
            const res = await fetch(`${this.$api_url}/backend/attachments/crud.php`,{
                method: "POST",
                body: formData,
                credentials: 'include'
            })
            if(res.status != 200){
                this.$parent.$parent.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                for (var attachment in json){
                    (typeof(json[attachment]) == 'object') ? json[attachment].location = this.$api_url +'/'+ json[attachment].location : 0;
                }
                json.updated != undefined ? this.attachmentsEdit('list',json.updated) : 0;
                json.removed != undefined ? this.removeSelectedAttachment(json.removed) : 0;
                json.update != undefined ? this.updateSelectedAttachment(json[0]) : 0;
                json.errors != undefined ? this.$parent.$parent.$parent.setDialog('errors',json[0]) : 0;
                if(json.list != undefined){
                    delete json['list'];
                    this.attachmentList = json
                }
            }
        },
        updateSelectedAttachment: function (_data){
            delete _data['update'];
            var index = Object.keys(this.attachmentList).length +1;
            this.$set(this.attachmentList,index,_data);
        },
        removeSelectedAttachment: function (_id){
            var cur_index = this.getIndexFromId(this.attachmentList,_id);
            this.$delete(this.attachmentList,[cur_index])
        },
        getIndexFromId: function (_obj,_id){
            var _index = -1
            for (var value in _obj){
                if(_obj[value].id == _id){
                    _index = value
                }
            }
            return _index;
        }
    }
}
</script>