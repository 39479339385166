<template>
    <div style="min-height: 100vh;" class="bg-light w-100 position-absolute">
        <div style="margin-bottom:56px;">
            <div v-if=authenticate>
                <div v-if="dialog != false" style="z-index:100" class="position-fixed vw-100">
                    <transition name="modal">
                        <div style="z-index:10000;" class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div v-if="dialog.errors != undefined">
                                        <h3 class="text-danger">Attenzione!</h3>
                                        <div v-for="(error,index) in dialog.errors" :key=index>
                                            <p class="text-danger">{{error}}</p>
                                        </div>
                                    </div>
                                    <div v-if="dialog.infos != undefined">
                                        <div v-for="(info,index) in dialog.infos" :key=index>
                                            <p>{{info}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="dialog = false">Chiudi</button>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="d-flex justify-content-center">
                    <div style="margin-top:80px; width:1200px;">
                        <div v-if="tutorial"><TutorialsForm/></div>
                        <div v-if="profile"><ProfileForm/></div>
                    </div>
                </div>
                <div v-if="!profile && !hardware && !tutorial">
                    <div class="container-fluid">
                        <div class="row d-flex flex-column justify-content-center">
                            <h1 style="opacity:75%; color:#000000" class="text-center w-100"><strong>WOW SUCH EMPTY!</strong></h1>
                            <div class="d-flex justify-content-center">
                                <img style="opacity:75%; max-height:400px; max-width:300px;" class="flex-grow-1" src="../../assets/img/empty.png">
                            </div>
                        </div>
                    </div>
                </div>
                <Slide right :closeOnNavigation="true">
                    <div class="text-white d-flex justify-content-center">
                        <div class="text-center w-100" style="width:32px;" @click="showSelected('profile')">
                            <i class="fa fa-user-circle fa-2x"></i>
                            <p><i v-if="profile" class="mr-2 fas fa-dot-circle"></i><strong>Modifica Profilo</strong></p>
                        </div>
                    </div>
                    <div class="text-white d-flex justify-content-center">
                        <div class="text-center w-100" style="width:32px;" @click="showSelected('hardware')">
                            <i class="fa fa-memory fa-2x"></i>
                            <p><i v-if="hardware" class="mr-2 fas fa-dot-circle"></i><strong>Crea Prodotti</strong></p>
                        </div>
                    </div>
                    <div class="text-white d-flex justify-content-center">
                        <div class="text-center w-100" style="width:32px;" @click="showSelected('tutorial')">
                            <i class="fa fa-pager fa-2x"></i>
                            <p><i v-if="tutorial" class="mr-2 fas fa-dot-circle"></i><strong>Crea Guida</strong></p>
                        </div>
                    </div>
                    <div class="text-white d-flex justify-content-center">
                        <div class="text-center w-100" style="width:32px;" @click="logoutProcess()">
                            <i class="fa fa-sign-out-alt fa-2x"></i>
                            <p><strong>Logout</strong></p>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    </div>
</template>

<script>

import ProfileForm from '../../components/backoffice/ProfileForm.vue'
import TutorialsForm from '../../components/backoffice/TutorialsForm.vue'
import { Slide } from 'vue-burger-menu'

export default {
    name: 'AdminArea',
    data: function(){
        return {
            authenticate: false,
            profile: false,
            tutorial: false,
            hardware: false,
            dialog: false,
            csrf_token: '',
        }
    }, 
    components:{
        ProfileForm,
        TutorialsForm,
        Slide
    },
    beforeMount() {
        this.fetchAutenticate();
    },
    methods: {
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        /*UI*/
        showSelected: function(selected){
            for(var value in this._data){
                if(value != "authenticate")
                value != selected ? this[value] = false : this[value] = !this[value];
            }
        },
        setDialog(value,data){
            this.dialog = {}
            console.log(data);
            this.dialog[value] = this.$root.responseTranslator(value,data);
        },
        /*CRUD*/
        logoutProcess: async function(){
            const res = await fetch(`${this.$api_url}/backend/logout.php`,{
                method: "POST",
                body: JSON.stringify({username:this.username, password:this.password}),
                credentials: 'include'
            });
            if(res.status != 200){
                this.setDialog('errors',['unexpected_error'])
            }else{
                this.$cookies.remove('access_token');
                this.$router.replace('/');
            }
        },
        async fetchAutenticate (){
            await this.getToken();
            const res = await fetch(`${this.$api_url}/backend/autenticate.php`,{
                method: "POST",
                body: JSON.stringify({access_token:String(this.$cookies.get('access_token')),csrf_token:this.csrf_token}),
                credentials: 'include'
            });
            if(res.status != 200){
                this.$router.replace('/');
            }else{
                const json = await res.json();
                json.accepted != 'undefined' && json.accepted ? this.authenticate = true : 0 ;
            }
        }
    }
}
</script>

<style>
    img{
        max-width: 100%;
    }
    h1, h2{
        color:#000000;
    }
</style>